<template>
  <UiDialogBasic
    :open="isVisible"
    persistent
    backdrop="none"
    hide-close-button
    :animated="false"
    :modal="false"
    :classes="{
      root: 'z-40',
      panel: [
        'p-xs min-md:left-[unset] min-md:right-4 bottom-4 top-[unset] max-w-sm transform-none md:max-w-[unset] md:rounded-md',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-bottom-1/4Ï data-[state=open]:slide-in-from-bottom-1/4Ï',
      ],
    }"
  >
    <UiDialogTitle class="sr-only">{{ $t("cookies.title") }}</UiDialogTitle>

    <div class="bg-muted p-s grid place-content-center rounded-md">
      <NuxtImg :src="getImageUrl('icons/cookie.webp')" loading="eager" style="height: 80px" />
    </div>

    <strong class="mb-xxxs mt-xxs block font-semibold" v-text="$t('cookies.title')" />

    <i18n-t
      class="text-muted-foreground prose-strong:font-semibold whitespace-pre-line text-base font-light"
      keypath="cookies.rich_text"
      tag="p"
    >
      <template #policy_link>
        <UiLink class="font-semibold underline underline-offset-4" :to="{ name: 'privacy' }">
          {{ $t("cookies.cookie_policy") }}
        </UiLink>
      </template>
    </i18n-t>

    <div class="mt-s flex flex-col gap-2">
      <UiButton @click="accept">{{ $t("cookies.accept") }}</UiButton>
      <UiButton @click="decline" variant="outline">{{ $t("common.decline") }}</UiButton>
    </div>
  </UiDialogBasic>
</template>

<script setup lang="ts">
const isVisible = ref(false)
const gtm = useGtm()
const cfg = useAppConfig()

const accept = () => {
  gtm.enableCookies()
  isVisible.value = false
}

const decline = () => {
  gtm.disableCookies()
  isVisible.value = false
}

onMounted(() => {
  if (!cfg.analytics?.enabled) {
    return
  } else if (gtm.isFirstTime.value) {
    isVisible.value = true
  }
})
</script>
